.Card {
  position: relative;
  cursor: pointer;
  background: #fff;
  margin: 8px;
  padding: 10px 12px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
  border-radius: 4px;  
  overflow-wrap: break-word;
  min-height: 18px;
}

.Card:hover {
  background: #f5f6f7;
}

.cardName {
  font-size: 15px;
  line-height: 21px;  
}

.cardData p {
  font-size: 12px;
  line-height: 12px;
  color: #777;
  margin: 3px 0;
}

.Card-Icons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Card-Icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 1px;
  color: rgba(0, 0, 0, 0.5);
  background: #f5f6f7;
  opacity: 0.9;
}

.Card-Icon:hover {
  opacity: 1;
  background: rgba(220, 220, 220, 1);
}

.cardGoalClient {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

  .cardGoalClientLogo {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    flex-shrink: 0;
  }

    .cardGoalClientLogo img {
      width: 100%;
      border-radius: 4px;
    }

  .cardGoalClientName {
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #7A7A7A;
  }

.cardTaskName {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #141414;
  margin-bottom: 8px;
} 

.cardGoalName {
  font-size: 14px;
  line-height: 17px;
  color: #7A7A7A;
  margin-bottom: 8px;
}

.cardUsersCardComments {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px 0;
}

  .cardUsersCardCommentsUsers {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

    .cardUsersCardCommentsUsersUser {
      position: relative;
      margin-right: -8px;      
    }

    .cardUsersCardCommentsUsersUser:nth-child(0) {
      z-index: 10;
    }

    .cardUsersCardCommentsUsersUser:nth-child(1) {
      z-index: 9;
    }

    .cardUsersCardCommentsUsersUser:nth-child(2) {
      z-index: 8;
    }

    .cardUsersCardCommentsUsersUser:nth-child(3) {
      z-index: 7;
    }

    .cardUsersCardCommentsUsersUser:nth-child(4) {
      z-index: 6;
    }

    .cardUsersCardCommentsUsersUser:nth-child(5) {
      z-index: 5;
    }

    .cardUsersCardCommentsUsersUser:nth-child(6) {
      z-index: 4;
    }

    .cardUsersCardCommentsUsersUser:nth-child(7) {
      z-index: 3;
    }

    .cardUsersCardCommentsUsersUser:nth-child(8) {
      z-index: 2;
    }

    .cardUsersCardCommentsUsersUser:nth-child(9) {
      z-index: 1;
    }

    .cardUsersCardCommentsUsersUser:nth-child(10) {
      z-index: 0;
    }
    

    .cardUsersCardCommentsUsersUser:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .cardUsersCardCommentsUsersUser.taskManager:after {      
      background-image: url('../img/crown_gold.svg');      
    }

    .cardUsersCardCommentsUsersUser.taskPerformer:after {
      background-image: url('../img/star_green.svg'); 
    }

      .cardUsersCardCommentsUsersUser img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }

      .taskParticipants .cardUsersCardCommentsUsersUser {
        width: 44px;
        height: 44px; 
        object-fit: cover;
        object-position: center;
      }

      .taskParticipants .cardUsersCardCommentsUsersUser img {
        width: 44px;
        height: 44px;        
        border: 2px solid #FFFFFF;        
      }

      .taskParticipants .cardUsersCardCommentsUsersUser:after {
        display: none;
      }


  .commentRightPart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }    

  .cardUsersCardCommentsComments {
    padding: 5.5px 8px 5.5px 24px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #fff;   
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    background: #F94848 url('../img/bell_white.svg') 8px center no-repeat;
    background-size: 12px auto;
  }

  .cardUsersCardCommentsComments.grey {
    background-color: #ccc;
  }




.cardDeadLineCardUsedTime {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
} 

  .cardDeadLineCardUsedTimeDeadLine {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #141414;
    background: url('../img/lightning.svg') 6px 4px no-repeat;
    background-size: 8px auto;
    padding-left: 24px;
  }

  .cardDeadLineCardUsedTimeUsedTime {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

    .usedTime {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #141414;
      padding-left: 24px;
      background: url('../img/time_grey.svg') left center no-repeat;
      background-size: 20px auto;
    }

    .usedTimeOf {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #7A7A7A;
      padding: 0 4px;
    }

    .totalTime {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #141414;
    }