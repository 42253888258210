.List {
  background: #F0F0F0;
  flex-shrink: 0;
  /*width: 272px;*/
  width: 316px;
  height: fit-content; 
  margin: 0 6px 45px;
  border-radius: 8px;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
 
}

/* .List:last-child {
  margin-right: 16px;
} */

.List-Title {
  /*cursor: pointer;*/
  padding: 8px 16px;
  overflow-wrap: break-word;  
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #666;
}

.Lists-Cards {
  min-height: 48px;
}

.Toggle-Add-Card {
  cursor: pointer;
  margin: 0 8px 8px;
  padding: 12px 12px 12px 36px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #141414;
  border-radius: 4px;
  background: url('../img/grey_plus.svg') 8px center no-repeat;
  background-size: 24px auto;
}

.Toggle-Add-Card.dummyBox {
  background: transparent;
  cursor: default;
  height: 42px;
  /* padding: 0;
  height: 0; */
}

.Toggle-Add-Card:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.Toggle-Add-Card.dummyBox:hover {
  background: transparent;
}

/* .Toggle-Add-Card:hover {
  background-color: rgba(9, 45, 66, 0.13);
  color: #17394d;
  text-decoration: underline;
}

.Toggle-Add-Card ion-icon {
  margin-right: 2px;
} */

.List-TimeCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin: 8px 0;
}

  .ltc_normal {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;   
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #7A7A7A;
  }

    .ltc_normal span {
      display: inline-block;
      margin-left: 4px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      color: #141414;
    }

  .ltc_actual {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-align: right;
    color: #141414;

  }
