@font-face {
  font-display: swap;
  font-family: 'Segoe UI';
  src: url('fonts/SegoeUI-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Segoe UI';
  src: url('fonts/SegoeUI-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Segoe UI';
  src: url('fonts/SegoeUI.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Segoe UI';
	src: url('fonts/SegoeUI-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
  }

header, nav, menu, main, section, article, aside, address, footer, noscript {
	display: block;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

button {
	cursor: pointer;
	padding: 0;
	margin: 0;
	border: none;
}

textarea {	
	padding: 0;
	margin: 0;
	border: none;
}

a {
	text-decoration: none;
	color: #7a7a7a;
}

	a:hover {
		/*border: none;	*/
		color: #7a7a7a;
	}	

	a:active, a:hover, a:focus {
		outline: none !important;
	}

html {	
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}	

body {	
	height: 100%;
	width: 100%;
	background: #fafafa;
	-webkit-appearance: none;
	appearance: none;
	font-family: 'Segoe UI', Arial, sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #7a7a7a;
	margin: 0;
	padding: 0;
}

button {
	font-family: 'Segoe UI', Arial, sans-serif;
}
	
header, main, footer {
	width: 100%;
	float: left;
	clear: both;
	overflow: hidden;
}

*, ::after, ::before {
  box-sizing: border-box;
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: unset;
    }
}

@media (min-width: 1400px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 1170px;
	}
}

#root {
	height: 100%;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-right: var(--bs-gutter-x,1rem);
    padding-left: var(--bs-gutter-x,1rem);
}

	.row {    
		margin-right: calc(var(--bs-gutter-x)/ -1.5);
		margin-left: calc(var(--bs-gutter-x)/ -1.5);
	}

		.row > * {    
			padding-right: calc(var(--bs-gutter-x)/ 1.5);
			padding-left: calc(var(--bs-gutter-x)/ 1.5);   
		}

		.tmHeader {			
			height: 56px;
			background: rgba(102, 102, 102, 0.7);			
			flex-shrink: 0;	
		}

			.tmhWrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;				
				padding: 0 18px 0 8px;
				position: relative;
				/*z-index: 2;						*/
				z-index: 11;
				height: 100%;		
			}

		  .tmContent {
			/* position: relative; */
			overflow: auto;
			outline: none;			
			flex-grow: 1;
		  }	

		  	.tmcWrapper {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;			
				/* padding: 16px 0 0 0;	 */
				/* position: relative;						 */
				height: 100%;
				z-index: 1;						
			}

			.Plan .tmcWrapper {
				/* display: block; */
			}

		  .greenApp .tmContent {
			align-items: stretch;	
		  }
		  
		  .tmLeft {
			width: 180px;
			margin-right: 18px;
		  }
		  
			.menuContainer {
			  display: flex;
			  position: relative;  
			  padding: 10px;
			  border-radius: 4px 4px 0 0;
			  cursor: pointer;
			}
		  
			.menuContainer:hover {
			  background: rgba(102, 102, 102, 0.85);
			}
		  
			  .mcSelected {
				font-weight: 600;
				font-size: 18px;
				line-height: 18px;
				color: #fff; 
				position: relative;  
				padding-right: 14px;     
				white-space: nowrap;  
			  }
		  
			  .mcSelected:after {
				content: '';    
				position: absolute;
				top: 50%;
				margin-top: -2.5px;
				right: 0;
				width: 7px;
				height: 5px;
				background: url('img/dd_arrow_white.svg') center no-repeat;
				background-size: contain;
			  }
		  
			  .mcMenu {
				display: none;
				position: absolute;
				top: 35px;
				left: 0;
				background: rgba(102, 102, 102, 0.85);
				border-radius: 0 0 4px 4px;
				width: 100%;
				overflow: hidden;
			  }
		  
			  .menuContainer:hover .mcMenu {
				display: block; 
			  }
		  
				.mcMenu ul {
				  list-style: none;
				  margin: 0;
				  padding: 0;
				  display: flex;
				  flex-direction: column;
				  justify-content: flex-start;
				}
		  
				  .mcMenu ul li {
					font-weight: 400;
					font-size: 14px;
					line-height: 19px; 
				  }
		  
					.mcMenu ul li a {
					  padding: 5px 10px; 
					  color: #fff;
					  display: block; 
					  text-decoration: none; 
					  white-space: nowrap;  
					}
		  
					.mcMenu ul li a:hover {
					  background: rgba(102, 102, 102, 0.95);
					}
		  
					.mcMenu ul li:last-child a {
					  padding-bottom: 10px;    
					}
		  
		  .tmCenter {
			width: 100%;
			max-width: 1097px;
			display: flex;
			justify-content: flex-start;
			position: relative;
		  }
		  
			.contentPanels {
			  display: flex;
			  justify-content: flex-start;
			}
		  
			  .cpOnePanel {
				margin-right: px;
				padding: 4px 8px;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
				color: #fff;
				background: rgba(20, 20, 20, 0.2);
				border-radius: 4px;
				margin-right: 8px;
				cursor: pointer;
			  }
		  
			  .cpOnePanel.active {
				background: rgba(20, 20, 20, 0.4);
			  }
		  
			  .cpFilter {
				display: flex;
				position: relative;      
				cursor: pointer;
				width: 100%;
			  }  
			  
			  .contentPanels .cpFilter {
				margin-right: 24px;
			  }
		  
				.cpfSelected {
				  font-weight: 600;
				  font-size: 14px;
				  line-height: 17px;
				  color: #fff; 
				  background: rgba(0, 0, 0, 0.3);
				  position: relative;         
				  padding: 4px 8px 4px 28px;
				  border-radius: 4px;
				  white-space: nowrap;
				}

				.tmHeader .cpfSelected {
					background: rgba(0, 0, 0, 0.3);
					padding: 4px 8px 4px 28px;
				}
		  
				  /* .cpFilter:hover .cpfSelected {
				border-radius: 4px 4px 0 0; 
				}
		  */
				.cpfSelected:after {
				  content: '';    
				  position: absolute;
				  top: 50%;
				  margin-top: -4px;
				  left: 10px;
				  width: 8px;
				  height: 8px;
				  background: url('img/dd_filter_white.svg') center no-repeat;
				  background-size: 8px auto;
				}

				.cpfMenuShell {
					position: absolute;
					top: 0;
					left: 0;
					display: none;
					padding-top: 50px;
					width: 100%;
					z-index: 1;
				}	

				.cpFilter.menuDropDown .cpfMenuShell, .spBlock.menuDropDown .cpfMenuShell {
					display: block;
				}
		  
				.cpfMenu {				  
				  position: absolute;
				  top: 32px;
				  left: 0;
				  background: #fff;
				  box-shadow: 0 0 48px rgba(0, 0, 0, 0.15);
				  border-radius: 4px;
				  min-width: 250px;
				  padding: 8px;	
				}

				.taskTableClients .cpfMenu {	
					height: 210px;
   					overflow: auto;
				}

				.cpfMenu.topZero {
				  top: 0;
				}

				.cpfMenu.left55 {
				  left: -55px;
				}

				.cpfMenu.left50 {
					left: -50px;
				}

				.w100percent .spbBody .cpfMenu.topZero {
				  min-width: 234px;
				  max-height: 315px;
   				  overflow: auto;
				}

					.spbBody .cpfMenu {							
						max-width: 250px;
						min-width: 150px;
					}

					.w100percent .spbBody .cpfMenu {							
						max-width: 100%;
						width: 100%;
						min-width: 150px;
					}

						.spbBody .cpfMenu ul li {
							margin-top: 8px;	
						}

						.spbBody .cpfMenu.topZero ul li {
							margin-top: 4px;
						}

						.spbBody .cpfMenu ul li:first-child {
							margin-top: 0;	
						}

							.spbBody .cpfMenu ul li a {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								padding: 4px;	
								border-radius: 4px;
																
							}	

							.spbBody .cpfMenu ul li a span.nowrap {
								white-space: nowrap;
								overflow: hidden; 
								text-overflow: ellipsis;
								display: block;
							}

							.spbBody .cpfMenu ul li:last-child a {
								padding-bottom: 0;
							}

							.spbBody .cpfMenu ul li a:hover {
								background: rgba(0, 0, 0, 0.05);								
							}

							.spbBody .cpfMenu ul li a.checkedClass {
								background: rgba(0, 0, 0, 0.1);
							}

							.userListOneUserPlus {
								width: 24px;
								height: 24px;
								margin-right: 10px;		
								background: url('img/grey_round_plus.svg') center no-repeat;		
								background-size: cover;	
								flex-shrink: 0;	
							}

							.userListOneUserPlus.minus {
								background-image: url('img/grey_round_minus.svg');		
							}

							.userListOneUserPhoto {
								flex-shrink: 0;
								border: 2px solid #FFFFFF;
								border-radius: 50%;
								width: 44px;
								height: 44px;
								margin-right: 8px;
								background-position: center;
								background-repeat: no-repeat;
								background-size: cover;
							}

							.userListOneUserName {
								font-size: 14px;
								line-height: 20px;
								color: #141414;	
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
		  
				/* .cpFilter:hover .cpfMenuShell {
				  display: block; 
				} */

					.cpfmHeader {
						padding: 4px;						
						font-weight: 600;						
						line-height: 20px;
						color: #141414;
						border-radius: 4px;						
					}

					.cpfmHeader.canHover:hover {
						background: rgba(0, 0, 0, 0.05);
					}

					/*.cpfmBody {
					
					}*/

						.cpfmBodyBlock {
							margin-top: 12px;
							border-top: 1px solid rgba(0, 0, 0, 0.15);
							padding-top: 8px;
						}

						.cpf_type_1 .cpfmBodyBlock {
							margin-top: 0;
							border-top: none;
							padding-top: 0;
						}

							.cpfmBodyBlockRow {
								padding: 4px;
								position: relative;
							}

							.cpfmBodyBlockRow.nopadding {
								padding: 0;
							}

								.subMenu {
									position: absolute;
									top: 0;									
									right: -8px;
									display: none;
								}

								.subMenu.menuDropDown {
									display: block;	
								}

								.subMenu .cpfMenu.left55 {
									left: auto;
								}

								.subMenu .cpfMenu ul li a {
									display: flex;
									justify-content: flex-start;
									align-items: center;
									padding: 4px;
									border-radius: 4px;								
									color: #141414;
								}

								.cpfMenu ul.flexClass li {
									margin-top: 8px;
								}

								.cpfMenu ul.flexClass li:first-child {
									margin-top: 0;
								}

								.cpfMenu ul.flexClass li a {
									display: flex;
									justify-content: flex-start;
									align-items: center;
									padding: 4px;
									font-weight: 600;
									font-size: 14px;
									line-height: 20px;
									color: #141414;
									border-radius: 4px;	
								}

								.cpfMenu ul.flexClass li a.checkedClass {
									background: rgba(0, 0, 0, 0.1);
								}

								.cpfMenu ul.flexClass li:last-child a {
									padding-bottom: 4px;
								}


								.menuCheckbox {									
									position: relative;		
								}

									.menuCheckbox input[type=checkbox] {
										opacity: 0;
										z-index: -1;
										opacity: 0;
										margin: 4px 0 0 2px;
										position: absolute;
										top: 0;
										left: 0;
									}
									
									.menuCheckbox label {
										position: relative;
										padding-left: 24px;										
										cursor: pointer;
										line-height: 20px;
										color: #141414;
										display: flex;
								  		justify-content: flex-start;
										align-items: center;
									}

									.menuCheckbox label:before {
										content: "";
										position: absolute;
										left: 0;
										top: 50%;
										margin-top: -8px;
										width: 16px;
										height: 16px;
										background: url('img/checkbox_disabled.svg') center no-repeat;
										background-size: contain;
									}

									.menuCheckbox input[type="checkbox"]:checked + label:before {
										background-image: url('img/checkbox_enabled.svg');	
									}

										.menuCheckboxLabelMark {
											width: 24px;
											height: 24px;											
											border-radius: 50%;
											text-align: center;
											line-height: 24px;											
											font-weight: 700;
											font-size: 11px;
											color: rgba(20, 20, 20, 0.5);
											text-transform: uppercase;
											margin-right: 8px;
										}

											.fixedSum {
												background: #CCE8FC;
											}

											.repeatGoal {
												background: #FCF7CC;
											}

										.menuCheckboxLabelText {
											line-height: 20px;
											color: #141414;	
										}
		  
				  .cpfMenu ul {
					list-style: none;
					margin: 0;
					padding: 0;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
				  }
		  
					.cpfMenu ul li {
					  font-weight: 400;
					  font-size: 13px;
					  line-height: 18px; 
					  white-space: nowrap;
					}

				      .cpfMenu ul.boardMenuList li {
						padding: 4px;
						font-size: 14px;
						line-height: 20px; 
						margin-top: 4px;
						color: #141414;
						border-radius: 4px;
					  }

					  .cpfMenu ul.boardMenuList.hasSubMenu li {
						position: relative;
					  }

					  .cpfMenu ul.boardMenuList.hasSubMenu li:after {
						content: '';
						position: absolute;
						right: 2px;
						top: 2px;
						width: 24px;
						height: 24px;
						background: url('img/arrow_right_grey.svg') center no-repeat;
						background-size: contain;
					  }

					  .cpfMenu ul.boardMenuList.hasSubMenu.subordinatesMenu li:after {						
						background-image: url('img/grey_round_plus.svg');					
					  }

					  .cpfMenu ul.boardMenuList li:hover {
						background: rgba(0, 0, 0, 0.05);
					  }

					  .cpfMenu ul.boardMenuList li.active {
						background: rgba(0, 0, 0, 0.1);
					  }
		  
					  .cpfMenu ul li a {
						padding: 5px 10px; 
						color: #fff;
						display: block; 
						text-decoration: none;
					  }
		  
					  .cpfMenu ul li a:hover {
						background: rgba(0, 0, 0, 0.05);   
					  }

					  .cpfMenu ul li a.cpfMenuSelectedItem {
						background: rgba(0, 0, 0, 0.2);   	
					  }	
		  
					  .cpfMenu ul li:last-child a {
						padding-bottom: 10px;    
					  }		  
		  
		  .tmRight {
			width: 396px;
			margin-left: 18px;
		  }
		  
			.userBlock {
			  display: flex;
			  justify-content: flex-end;
			  align-items: center;
			}
		  
			  .ubName {
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				color: #fff;
				margin-right: 16px;
			  }
		  
			  .ubImg {
				width: 40px;
				height: 40px; 
				border-radius: 50%;   
				border: 2px solid #fff;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			  }				  
			  
		.tmFooter {
			position: fixed;
			left: 0;
			bottom: 16px;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			z-index: 11;	
			max-height: 80%;
			overflow: auto;		
		}	
		
			.tmFooterHeader {
				background: #666;				
				height: 40px;
				padding: 8px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100vw;
   				flex-grow: 0;
				cursor: pointer;				
				position: relative;
			}	
			
			.tmFooterHeader.active {
				cursor: default;	
			}

				.tmFooterHeaderTitle {
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
					color: #fff;
					margin-left: 8px;
					margin-right: 16px;
				}

				.tmFooterHeaderSwitch {
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}

					.tmFooterHeaderSwitchBlock {
						width: 24px;
						height: 24px;
						margin-right: 8px;
						border-radius: 4px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						cursor: pointer;
					}

					.tmFooterHeaderSwitchBlock:hover {
						background: rgba(0, 0, 0, 0.1);
					}

					.tmFooterHeaderSwitchBlock.active {
						background: rgba(0, 0, 0, 0.4);
					}
					
						.tmFooterHeaderSwitchBlock.switchFirst {
							background-image: url('img/switchOne.svg');
						}

						.tmFooterHeaderSwitchBlock.switchSecond {
							background-image: url('img/switchSecond.svg');
						}

						.tmFooterHeaderSwitchBlock.switchThird {
							background-image: url('img/switchThird.svg');
						}

				.tableClose	{
					position: absolute;
					top: 50%;
					margin-top: -7px;
					right: 8px;
					width: 14px;
					height: 14px;
					background: url('img/table_white_close.svg') center no-repeat;
					background-size: 14px auto;
					display: none;
				}	

			.tmFooter .cpFilter {
				margin-left: 16px;
				width: auto;
				display: none;
			}

			.tmFooter .tmFooterHeader.active .cpFilter, .tmFooter .tmFooterHeader.active .tableClose {
				display: block;
			}





			
			/* .tmFooter .cpfmBodyBlock {
				margin-top: 0; 
				border: none;
				padding: 0;
			} */

			.tmFooter .cpfMenu ul li a {
				position: relative;
			}

			.statusName {
				font-size: 12px;
				line-height: 14px;
				text-align: center;
				color: #141414;
				padding: 6px 12px;
				border: 1px solid rgba(0, 0, 0, 0.05);
				border-radius: 24px;				
			}

			.statusName.pink {
				background: #FFD9D9;
			}

			.statusName.green {
				background: #4BBF6B;
			}

			.statusName.yellow {
				background: #FCF7CC;
			}

			.statusName.blue {
				background: #CCDBF1;
			}

			.statusName.grey {
				background: #666;
				color: #fff;
			}


			.tmFooter .cpfMenu ul li a.listPlus.minus:after {
				background-image: url('img/grey_round_minus.svg');
			}

			.tmFooter .cpfMenu ul li a.listPlus:after {
				content: '';
				position: absolute;
				top: 5px;
				right: 4px;
				width: 24px;
				height: 24px;				
				background: url('img/grey_round_plus.svg') center no-repeat;
				background-size: cover;				
			}

			.topClass:last-child {
				position: relative;
				margin-bottom: 24px;
			}

			.topClass:last-child:before {
				content: '';
				position: absolute;
				bottom: -15px;
				left: 0;
				width: 100%;
				height: 1px;				
				background: rgba(0, 0, 0, 0.15);				
			}


			/* .tmFooter .cpfMenu {				
				top: 26px;
			}	 */

			.tmFooterBody {
				background: #fff;
				width: 100%;	
				overflow: auto;	
				flex-grow: 1;		
			}

			.tmFooterBody.hiddenBlock {
				height: 0;
				/* display: none;		 */
			}

				.tmFooterBodyTable {
					min-height: 222px;
				}

			/* .tmFooterBodyTable {
				transform: scaleY(1);
				transition: transform .3s ease-in-out;
				transform-origin: bottom;
			} */

				/* .tmFooterBodyTable {

				} */

					.tmFooterBodyTable table {
						border-spacing: 0;
						border-collapse: collapse;
						width: 100%;
					}

						/* .tmFooterBodyTable table thead {

						}

							.tmFooterBodyTable table thead tr {

							} */

								.tmFooterBodyTable table thead tr th, .tmFooterBodyTable table tbody tr td {
									padding: 8px;
								}

								.tmFooterBodyTable table thead tr th {
									padding-top: 14px;
									padding-bottom: 12px;
								}

								.tmFooterBodyTable table tfoot tr td {
									padding: 20px 20px 20px 16px;
								}

								.tmFooterBodyTable table thead tr th {
									font-size: 11px;
									line-height: 11px;
									color: #7A7A7A;
									text-align: center;
									font-weight: 400;
								}

									.thCell, .cellNumbers {
										display: flex;
										flex-direction: column;
										justify-content: flex-start;
										align-items: center;
									}

										.thNumber {
											font-weight: 600;
											font-size: 14px;
											line-height: 14px;
											color: #7A7A7A;
											margin-bottom: 3px;
										}

										.thWeekDay {
											font-weight: 400;
											font-size: 11px;
											line-height: 11px ;
											text-transform: uppercase;
											color: #7A7A7A;
										}

										.redCell .thNumber, .redCell .thWeekDay {
											color: #BC7070;
										} 

										.blackCell .thNumber, .blackCell .thWeekDay, .todayCell .thNumber, .todayCell .thWeekDay {
											color: #141414;
											font-weight: 700;
										} 

										.tmFooterBodyTable table thead tr th.w65, .tmFooterBodyTable table tbody tr td.w65 {
											width: 81px;
										}

										.tmFooterBodyTable table thead tr th.w107, .tmFooterBodyTable table tbody tr td.w107 {
											width: 81px;
											padding: 0;
										}										

								.tmFooterBodyTable table thead tr th:first-child, .tmFooterBodyTable table tbody tr td:first-child {
									padding-left: 16px;
								}

								.tmFooterBodyTable table thead tr th.thCell, .tmFooterBodyTable table thead tr th.thCell:first-child, .tmFooterBodyTable table tbody tr td.greenBorder, .tmFooterBodyTable table tbody tr td.greenBorder:first-child {
									padding: 0;
								}

							/* .tmFooterBodyTable table tbody {

							} */

								.tmFooterBodyTable table tbody tr td, .tmFooterBodyTable table tfoot tr td {
									border-top: 1px solid rgba(0, 0, 0, 0.15);
								}

								.tmFooterBodyTable table tbody tr.hcRow, .tmFooterBodyTable table tbody tr.hcRow td, .tmFooterBodyTable table tbody tr.hcRow td * {
									position: relative;
									transition-property: line-height, font-size, height, padding, border;
 									transition-duration: .3s;
									transition-timing-function: ease-in-out;
								}

								.tmFooterBodyTable table tbody tr td.cellTask, .tmFooterBodyTable table tbody tr td.cellGoal {
									width: 50%;
								}

								

									/* .tmFooterBodyTable table tbody tr td {
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
									} */
		
									/* .tmFooterBodyTable table tbody tr td {
										padding: 8px;
									}
									
									.tmFooterBodyTable table tbody tr td:first-child {
										padding-left: 16px;
									} */

									.tmFooterBodyTable table tbody tr.hiddenCell {
										height: 0 !important;
										line-height: 0 !important;
										font-size: 0 !important;
										padding: 0 !important;
										margin: 0 !important;
										outline: none !important;
										border: none !important;
										
									}

									.tmFooterBodyTable table tbody tr.hiddenCell td {
										padding-top: 0 !important;
										padding-bottom: 0 !important;
										line-height: 0 !important;
										height: 0 !important;
										white-space: nowrap;
										overflow: hidden;
										border: none;
									}

									.tmFooterBodyTable table tbody tr.hiddenCell td * {
										height: 0 !important;
										padding-top: 0 !important;
										padding-bottom: 0 !important;
										border-width: 0;
										white-space: nowrap;
										overflow: hidden;
										line-height: 0 !important;
										/* display: none; */
										opacity: 0;										
										font-size: 0;
										line-height: 0;
									}

									.tmFooterBodyTable table tbody tr td.cellImage {
										padding-top: 3px;
										padding-bottom: 2px;
										line-height: 12px;
									}

									.tmFooterBodyTable table tbody tr td.cellImage img {
										width: 40px;
										height: 40px;
										object-fit: cover;
										object-position: center;
										border-radius: 4px;
									}

									/* .tmFooterBodyTable table tbody tr td.cellTask, .tmFooterBodyTable table tbody tr td.cellGoal {
										
									} */

									.tmFooterBodyTable table tbody tr td.cellTask div, .tmFooterBodyTable table tbody tr td.cellGoal div {
										font-size: 14px;
										line-height: 17px;
										color: #7A7A7A;
										max-height: 34px;
										overflow: hidden;
										text-overflow: ellipsis;
									}

									.tmFooterBodyTable table tbody tr td.cellGoal div {
										font-weight: 600;
										color: #141414;
									}

									.tmFooterBodyTable table tbody tr:hover td {
										background: #FAFAFA;
									}

									.cnCommon {
										font-size: 12px;
										line-height: 14px;
										text-align: center;
										letter-spacing: -0.03em;
										color: #7A7A7A;
									}									

									.cnMonth {
										font-weight: 600;
										font-size: 16px;
										line-height: 19px;
										text-align: center;
										letter-spacing: -0.03em;
										color: #141414;
									}

									.cnCommon.redCnCommon, .cnMonth.redCnCommon {
										color: #F94848;
									}

								.cellStatus {
									width: 65px;
									height: 26px;									
									border-radius: 24px;
									font-size: 12px;
									line-height: 22px;
									text-align: center;
									color: #141414;
									border: 1px solid rgba(0, 0, 0, 0.05);
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}	

								.cellStatus.pink {
									background: #FFD9D9;									
								}
								
								.cellStatus.green {
									background: #4BBF6B;									
								}

								.cellStatus.yellow {
									background: #FCF7CC;
								}

								.cellStatus.blue {
									background: #CCDBF1;
								}

								.cellStatus.grey {
									background: #666;
									color: #fff;
								}

								.tmFooterBodyTable table tbody tr td.redBell {
									padding-left: 0;
									padding-right: 18px;
								}

								.cellComments {
									width: 24px;
									height: 24px;
									background: url('img/bell_red.svg') center no-repeat;
									background-size: contain;
								}

								.greenBorder {
									border-left: 1px solid rgba(0, 0, 0, 0.15);
									border-right: 1px solid rgba(0, 0, 0, 0.15);
									position: relative;
								}

								.tmFooterBodyTable table tbody tr td.yellowBg {
									background: #FCF7CC;
								}

								.tmFooterBodyTable table tbody tr td.greenBg {
									background: #E8FFEE;
								}

								.todayCell {
									position: relative;
								}
								
								.greenBorder.greenBorderBottom:after, .greenBorder.yellowBorderBottom:after, .blackCell:after, .todayCell:after  {
									content: '';
									position: absolute;
									left: 0;
									bottom: 0;
									width: 100%;
									height: 4px;
									background: #4BBF6B;
								}

								.hiddenCell .greenBorder.greenBorderBottom:after, .hiddenCell .greenBorder.yellowBorderBottom:after, .hiddenCell .blackCell:after {
									height: 0;
									display: none;
								}

								.greenBorder.yellowBorderBottom:after {
									background: #FFD600;
								}

								.greenBorder.pinkBorderBottom:after {
									background: #FFD9D9;
								}

								.greenBorder.blueBorderBottom:after {
									background: #CCDBF1;
								}

								.greenBorder.greyBorderBottom:after {
									background: #666;
								}

								.blackCell:after, .todayCell:after {
									background: #141414;
								}

									.greenBorder input {
										font-size: 12px;
										line-height: 100%;;
										text-align: center;
										color: #141414;
										width: 32px;
										height: 50px;
										border: none;	
										background: transparent;									
									}

									.greenBorder input:focus-visible {
										outline: none;
									}

									.greenBorder input:focus {
										border: 1px solid #5385D0;
										background: #fff;
									}

							/* .tableLink {

							} */
							
								.tableLink span {
									font-size: 14px;
									line-height: 20px;
									color: #5385D0;
									position: relative;
									cursor: pointer;
								}

								.tableLink span:after {
									content: '';
									position: absolute;
									top: 7px;
									right: -17px;
									width: 7px;
									height: 5px;
									background: url('img/arrow_down_grey.svg') center no-repeat;
									background-size: cover;
								}


							tfoot.hiddenCell {
								display: none;
							}	