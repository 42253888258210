.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
	overflow-y: auto;
}

.fade {
    transition: opacity .25s linear;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    /* display: none; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal.fade.show .modal-dialog {
    transform: none;
}

/* .modal.fade .modal-dialog {
    transform: translate(0,25px); 
}
*/
.modal.show .modal-dialog {
    transform: none;
}

 /* .modal.fade .modal-dialog {
   transition: transform .3s ease-out;
    transform: translate(0,-50px);
}
 */
.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

/*
@media (min-width: 576px) {
	
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	
	.modal-dialog {
		position: relative;
		width: auto;
		margin: 0.5rem;
		pointer-events: none;
	}
	
}
*/

.modal-fullscreen .modal-content {
    min-height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-content {
    background: rgba(255,255,255,.5);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
   
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

.modal-body {
    padding-bottom: 0;
    align-items: center;
    display: flex;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}



.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
}

.row {
    margin-right: calc(var(--bs-gutter-x)/ -1.5);
    margin-left: calc(var(--bs-gutter-x)/ -1.5);
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2);
}

.mb_row > .col-12 {
    padding: 0;
}

.row > * {
    padding-right: calc(var(--bs-gutter-x)/ 1.5);
    padding-left: calc(var(--bs-gutter-x)/ 1.5);
}

.modal_box_content {
    border-radius: 4px;
    position: relative;
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 30px rgb(0 0 0 / 25%);
    padding-top: 40px;
    padding-bottom: 16px;
}

.mb_close {
    position: absolute;
    display: block;
    padding: 0;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgNEwyMCAyME0yMCA0TDQgMjAiIHN0cm9rZT0iI0ZGNTQwRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==) center no-repeat;
    background-size: contain;
    border: none;
    cursor: pointer;
}

.modal_form_title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 400;
    color: #00848a;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 24px;
    /* text-transform: uppercase; */
}

.commentDiv {
    font-size: 12px;
    line-height: 16px;
    color:#777;
}

.statusDiv {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    color:#777;
}

@media (min-width: 576px) {
	.container, .container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container, .container-md, .container-sm {
		max-width: 750px;
	}
}

@media (min-width: 1170px) {
	.container, .container-lg, .container-md, .container-sm {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl {
		max-width: 1140px;
	}
}

@media (min-width: 1400px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 1140px;
	}
}

@media (min-width: 1170px) {
	.offset-lg-1 {
		margin-left: 8.3333333333%;
	}
}

@media (min-width: 1170px) {
	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.3333333333%;
	}
}

