.Textarea {
	margin-bottom: 10px;
	width: 100%;
    max-width: 437px;
}

.Textarea.nomargin {
	margin: 0;
}

.Textarea.nomaxwidth {
	max-width: none;
	margin-right: 16px;
}

.Textarea label {
	margin-bottom: 3px;
	padding: 0;
	display: block;
	font-weight: bold;
}

.Textarea textarea {
	display: block;
	box-sizing: border-box;
	border: 1px solid transparent;
	padding: 7px;
	margin: 0 0 5px;
	width: 100%;
	height: 39px;
	outline: none;
	/* transition: all 300ms ease-in-out; */
}

.Textarea span {
	color: #f01f30;
	font-size: 12px;
	font-weight: bold;
}

.Textarea.invalid label {
	color: #f01f30;
}