.userTaskBox {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	z-index: 0;
}

	.userTaskBoxPhoto {
		width: 44px;
		height: 44px;
		margin-right: 7px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
	}

	.userTaskBoxPhoto:after {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		width: 16px;
		height: 16px;
		background: url('../img/crown_gold.svg') center no-repeat;
		background-size: contain;
	}

	#data_f_taskParticipantsIds .userTaskBoxPhoto:after {
		display: none;
	}

	.userTaskBoxPhoto.noUser {
		background: url('../img/grey_round_plus.svg') center no-repeat;		
		border: none;
	}

	.userTaskBoxPhoto.noUser:after {
		display: none;
	}

	.userTaskBoxPhoto.noCrown:after {
		background: url('../img/star_green.svg') center no-repeat;
	}

	.userTaskBoxName {
		font-size: 11px;		
		color: #141414;
		white-space: nowrap; 
    	overflow: hidden; 
   		text-overflow: ellipsis;   		
	}

		.userTaskBoxNameAdd {
			color: #5385D0;
			text-decoration: underline;
		}

		.userTaskBoxNameAdd:hover {
			text-decoration: none;
		}