.contentPanels .cpFilter.newFilter {
    margin-right: 8px;
}

.newFilter .cpfMenu ul li {
	font-family: 'Segoe UI', Arial, monospace;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;	
}

.newFilter .cpfMenu {
	padding: 8px 4px;	
	/*max-width: 100%;
	min-width: 152px;*/
	
	width: 100%;
	/* min-width: 100%; */
}

.newFilter .cpfMenu ul {
	max-height: 176px;
	overflow: auto;
	scrollbar-width: thin;
    scrollbar-color: rgba(122, 122, 122, 1) rgba(217, 217, 217, 1);
}

/* width */
.newFilter .cpfMenu ul::-webkit-scrollbar {
	width: 4px;
}
  
/* Track */
.newFilter .cpfMenu ul::-webkit-scrollbar-track {
	width: 4px;
	border-radius: 14px;
	background: rgba(217, 217, 217, 1);
}

/* Handle */
.newFilter .cpfMenu ul::-webkit-scrollbar-thumb {
	background: rgba(122, 122, 122, 1);
	width: 4px;
	border-radius: 14px;
}

/* Handle on hover */
.newFilter .cpfMenu ul::-webkit-scrollbar-thumb:hover {
background: #555;
}

.newFilter .cpfMenu ul li a {
	color: rgba(20, 20, 20, 1);
	padding: 4px;
}

.newFilter .cpfMenu ul li:last-child a {
    padding: 4px;
}

.newFilter .cpfMenu ul li a.checkedClass {
	color: rgba(75, 191, 107, 1);
}