.Select {
	margin-bottom: 10px;
}

.Select label {
	margin-bottom: 3px;
	padding: 0;
	display: block;
	font-weight: bold;
}

.Select select {
	display: block;
	box-sizing: border-box;
	border: 1px solid #bebebe;
	padding: 7px;
	margin: 0 0 5px;
	width: 100%;
	outline: none;
	transition: all 300ms ease-in-out;
}

.Select span {
	color: #f01f30;
	font-size: 12px;
	font-weight: bold;
}

.Select.invalid label {
	color: #f01f30;
}