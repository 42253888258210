.App {   
  display: flex;
  flex-direction: column;
  /* overflow-x: hidden; */
  position: relative;
  min-height: 100%;
}

.App.greenApp {
  height: 100%;
  background-color: #4BBF6B;
}

.Header {
  background: rgba(0, 0, 0, 0.15);
  color: white;
  padding: 5px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  text-align: center;
  font-size: 40px;
  font-weight: 200;
  height: 100px;
}

.filterMonthsBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.filterMonthsBox.column {
  flex-direction: column;
  align-items: flex-start;
}

  .filterMonth {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;    
    color: #FFFFFF;
    padding: 4px 8px;
    background: rgba(20, 20, 20, 0.2);
    border-radius: 4px;
    white-space: nowrap;
    margin-right: 8px;
    cursor: pointer;
  }

  .column .filterMonth {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .column .filterMonth:last-child {
    margin-bottom: 0;
  }

  .filterMonth.active {
    background: rgba(20, 20, 20, 0.4);
  }

  .condition_or {
    color: #fff;
    margin: 0 12px;
    line-height: 25px;
  }

  .cpFilter + .condition_or {
    margin-left: -12px;
  }
