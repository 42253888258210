.Plan {
	/* padding: 16px; */
	height: 100%;
	flex-grow: 1;
    display: flex;
}

/*.tmPlanClients {
	
}*/

	.tmpcHeader {
		font-weight: 600;
		font-size: 10px;
		line-height: 12px;
		letter-spacing: 0.05em;
		text-transform: uppercase;		
		margin-bottom: 13px;
	}

	/*.tmpcBody {

	}

		.tmpcBody ul {

		}*/

			.tmpcBody ul li {
				margin-bottom: 8px;
			}

				.tmpcBody ul li a {
					padding: 4px;	
					display: flex;
					justify-content: flex-start;
					align-items: center;
					border-radius: 4px;
				}

				.tmpcBody ul li a:hover {
					background: rgba(0, 0, 0, 0.05);
				}

				.tmpcBody ul li a.active {
					background: rgba(0, 0, 0, 0.1);	
				}

					.clientLogo {
						width: 24px;
						height: 24px;
						margin-right: 8px;
						flex-shrink: 0;
					}

						.clientLogo img {
							width: 24px;
							border-radius: 4px;	
						}

					.clientName {
						/* font-weight: 600; */
						font-size: 14px;
						line-height: 20px;						
						color: #141414;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;						
					}

					.clientName.defaultClient {
						position: relative;
						padding-left: 34px;
						padding-top: 2px;
						padding-bottom: 2px;
						display: inline-block;
						height: 24px;
						line-height: 24px;
					}

					.clientName.defaultClient:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 24px;
						height: 24px;
						background: url('../img/grey_round_plus.svg') center no-repeat;
						background-size: contain;
					}

.tmCenter.tmCenterPlan {
	flex-direction: column;
}

.goalListWrapper {
	margin-bottom: 32px;
	min-width: 670px;
}

.clientDataHeader {
	margin-bottom: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

	.cdhLeft {
		display: flex;
		align-items: flex-start;
	}

		.cdhlLogo {
			width: 40px;
			margin-right: 14px;
			flex-shrink: 0;
		}

			.cdhlLogo img {
				width: 40px;
			}

		.cdhlName {
			margin-right: 14px;
			font-weight: 600;
			font-size: 24px;
			line-height: 29px;
			color: #141414;
		}

		.cdhlMonth {
			font-size: 24px;
			line-height: 29px;
			color: #7a7a7a;	
			margin-right: 14px;
		}

	/*.cdhRight {
		width: 187px;
	}

		.cdhRight a, .cdhRight button {
			display: inline-block;
			background: rgba(0, 0, 0, 0.05) url('../img/grey_plus.svg') 8px center no-repeat;
			background-size: 24px auto;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 24px;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #141414;
			padding: 12px 12px 12px 36px;
			white-space: nowrap;			
		}
	*/
	
	.goalAddButton {
		position: absolute;
		top: 0;
		right: 0;
	}

		.goalAddButton button {
			display: inline-block;
			/* position: absolute;
			top: 0;
			right: 0; */
			background: rgba(0, 0, 0, 0.05) url('../img/grey_plus.svg') 8px center no-repeat;
			background-size: 24px auto;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 24px;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #141414;
			padding: 12px 12px 12px 36px;
			white-space: nowrap;
			margin-left: 13px;			
		}

		.goalAddButton button.refreshBtn {
			background-image: url('../img/refresh_grey.svg');
		}

/*.clientGoals {

}*/	

	.cgGoalCategory {
		margin-bottom: 16px;
	}

	.specGoals .cgGoalCategory {
		/* margin-bottom: 48px; */
		margin-bottom: 0;
	}

		.cgGoalCategoryName {
			font-weight: 700;
			font-size: 11px;
			line-height: 19px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #7a7a7a;
			padding: 10.5px 8px;
		}

		.specGoals .cgGoalCategoryName {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-weight: 600;
			font-size: 10px;
			line-height: 12px;
			letter-spacing: 0.05em;
			/* padding: 8px 0; */
			padding: 8px;			
		}

		.specGoals .cgGoalCategoryName.solidGrey {
			font-size: 14px;
			line-height: 19px;
			color: #fff;
			padding: 10.5px 8px;
			background: #7A7A7A;
			margin-top: 32px;
			font-weight: 400;
			text-transform: none;
			letter-spacing: 0;
		}

		.cgGCNName, .specGoals .goalRowMarks {			
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.cgGCNName {
			flex-grow: 1;
			color: #141414;
		}

		.boldText {
			font-weight: 600;
		}

		.specGoals .goalRowMarks.w37 {		
			width: 37px;
		}

		.cgGCNPlan {
			width: 75px;
			margin: 0 9px;
			flex-shrink: 0;
			color: #141414;
		}

		.cgGCNExecutor {
			width: 115px;
			margin: 0 9px;
			flex-shrink: 0;
			color: #141414;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.cgGCNUsedTime {
			width: 65px;
			margin: 0 9px;
			flex-shrink: 0;
			color: #141414;
		}

		.cgGCNUsedTime.redText {
			color: #F94848;
		}

		.cgGCNUsedTime.greyText {
			color: #7A7A7A;
		}

		.cgGCNCorrection {
			width: 60px;
			margin: 0 9px;
			flex-shrink: 0;
			color: #141414;
		}

		.cgGCNStatus {
			width: 60px;
			margin: 0 9px;
			flex-shrink: 0;
			color: #141414;
		}

			.scWrapper {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}

				.sumCorrText {
					font-size: 14px;
					line-height: 20px;
					color: #141414;
					margin-right: 3px;
					white-space: nowrap;
				}

		.setResetButton {
			width: 24px;
			height: 24px;
			background: rgba(0, 0, 0, 0.05) url('../img/refresh_grey.svg') center no-repeat;
			background-size: 24px auto;			
			border-radius: 50%;
		}

		.cgGCNResult {
			width: 65px;
			margin: 0 9px;
			flex-shrink: 0;
			color: #141414;
		}

			.cgGCNResult a {
				text-decoration: underline;
				color: #5385D0;
			}

			.cgGCNResult a:hover {
				text-decoration: none;				
			}

		.cgGCNSet {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 70px;
			margin: 0 10px 0 15px;
			flex-shrink: 0;
		}

		.noBill .goalRowName, .noBill .cgGCNPlan, .noBill .cgGCNExecutor, .noBill .cgGCNUsedTime, .noBill .cgGCNResult {
			font-style: italic;
		}

		/*.cgGoalCategoryList {

		}*/

			.cggcl_row {
				background: #fff;
				/* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35); */
				border-radius: 4px;
				margin-bottom: 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				/* padding: 12px 8px; */
				padding: 8px;
				cursor: pointer;
			}

			.specGoals .cggcl_row {
				padding: 8px 8px 6px 6px;
				box-shadow: none;
				border-radius: 0;
				margin: 0;
				border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			}

			.specGoals .cggcl_row.noBg {
				background: transparent;
				cursor: default;
			}

			.cgGoalCategory.greyBg .cggcl_row {
				background: transparent;
				box-shadow: none;
				border-radius: 0;
				margin-bottom: 0;		
			}

			.cggcl_row.noBill {
				position: relative;
			}

			.cggcl_row.noBill:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 34px;
				width: 77%;
				height: 1px;
				margin-top: 2px;
				background-color: rgba(0, 0, 0, 0.7);
			}

			.cggcl_row:hover {
				background: #f5f5f5;
			}

				.goalRowLeft {
					display: flex;
					flex-grow: 1;
				}

					/*.goalRowMarks {

					}*/

						.goalRowMarks span {
							display: inline-block;
							margin-right: 4px;
							width: 24px;
							height: 24px;
							border-radius: 50%;
							font-weight: 700;
							font-size: 11px;
							line-height: 24px;							
							text-align: center;
							color: rgba(20, 20, 20, 0.5);
						}

						.goalRowMarks span.fixedSum {
							background: #cce8fc;
						}

						.goalRowMarks span.repeatGoal {
							background: #fcf7cc;
						}

					.goalRowName {
						flex-grow: 1;
						padding: 2px 8px;
						margin-right: 16px;
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						color: #141414;
					}

					.noBg .goalRowName {
						font-weight: 400;
					}

					.greyBg .goalRowName, .greyBg .goalRowSum, .greyBg .goalRowDeadline input {
						color: #7a7a7a;
					}

				.goalRowComment {
					width: 24px;
					margin-right: 16px;
				}

					.goalRowComment a, .goalRowComment button, .goalRowComment span {
						width: 24px;
						height: 24px;	
						display: inline-block;
						background: url('../img/comment_nobg.svg') center no-repeat;
						background-size: contain;						
					}

				.goalRowSum {
					padding: 4px 8px;
					text-align: right;	
					font-size: 14px;
					line-height: 20px;
					color: #141414;
					margin-right: 16px;
					white-space: nowrap;
				}

				.goalRowDeadline {					
					width: 150px;
					margin-right: 24px;
					flex-shrink: 0;
				}

				/* .goalRowDeadline .react-datepicker__input-container {
					
				} */

				.goalRowDeadline .react-datepicker__input-container:after {
					content: '';
					position: absolute;
					width: 7px;
					height: 5px;
					top: 50%;
					margin-top: -2.5px;
					right: 11px;
					background: url('../img/dd_arrow.svg') no-repeat;
					background-size: 7px auto;
				}

					.goalRowDeadline input {	
						padding: 4px 25px 4px 36px;
						margin-right: 24px;
						font-size: 14px;
						line-height: 20px;
						color: #141414;
						position: relative;
						background: url('../img/lightning.svg') 22px 8px no-repeat;
						background-size: 8px auto;
						/* cursor: pointer; */
						white-space: nowrap;
						flex-shrink: 0;
						border-radius: 100px;	
						width: 100%;
						border: none;
						border: 1px solid transparent;
					}

					.goalRowDeadline input:focus-visible {
						outline: none;
						
						border: 1px solid rgba(0, 0, 0, 0.15);	
					}

				.goalRowDeadline input:hover {
					background-color: #fff;
				}

				.goalRowTrash {
					width: 24px;
				}

					.goalRowTrash a, .goalRowTrash button {
						width: 24px;
						height: 24px;
						display: inline-block;
						background: url('../img/trash.svg') center no-repeat;
						background-size: contain;
					}

.tmBudgetBox {
	width: 396px;
	background: #fff;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-left: 1px solid rgba(0, 0, 0, 0.15);
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}					

	.tmbbRow {
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		padding: 8px;
		display: flex;		
	}

		.tmbbrName {
			margin-right: 16px;
			width: 148px;
			font-size: 14px;
			line-height: 19px;
			color: #141414;
			padding-left: 16px;
		}

		.tmbbRow.strong .tmbbrName {
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
			color: #141414;
			padding-left: 0;
		}

		.tmbbrSum {
			margin-right: 16px;
			width: 100px;
			font-size: 14px;
			line-height: 19px;
			color: #141414;
			text-align: right;
		}

		.tmbbRow.strong .tmbbrSum {
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
			color: #141414;
		}

		.tmbbrDifference {
			font-style: italic;			
			font-size: 16px;
			line-height: 21px;
			text-align: right;
			color: #4bbf6b;
		}

		.tmbbrDifference.redText {
			color: #F94848;
		}

.tmSidePanel {
	/* position: absolute;
	top: 0;
	right: -485px; */
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	/* width: 485px; */
	width: 0;
	background: #fff;	
    /* transition: transform 300ms ease-in-out; */
	transition: width 300ms ease-in-out;
	box-shadow: 0 0 48px rgba(0, 0, 0, 0.15);	
	/* overflow: auto; */
    padding-bottom: 24px;
	z-index: 11;
	/* margin-top: -15px; */	
	flex-shrink: 0;
}

.Plan .tmSidePanel {	
	/* top: -15px;
	right: -500px; */
}

.tmSidePanel.w640 {
	/* right: -640px; */
	/* height: 100%; */
	/* width: 640px; */
}

.tmSidePanel.fixed {
	position: fixed;	
}

.tmSidePanel.active {
	/*right: 0;*/
	/* transform: translate3d(-485px, 0, 0); */
	/* width: 485px; */
	/* top: -15px; */
	right: 0;
	/* position: relative; */
	width: 640px;
	position: fixed;
	top: 56px;
	padding-bottom: 70px;
}

.Plan .tmSidePanel.active {
	/* height: calc(100% + 15px);
	margin-right: -15px; */
}

.tmSidePanel.w640.active {	
	/* transform: translate3d(-640px, 0, 0); */
	width: 640px;
}

	.tmspHeader {
		padding: 12px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

		/*.tmTrash {
		
		}*/

			.tmTrash a, .tmTrash button {
				display: inline-block;
				width: 24px;
				height: 24px;
				background: url('../img/trash.svg') center no-repeat;
				background-size: contain;
			}

		/*.tmClose {

		}*/
		
			.tmClose a, .tmClose button {
				display: inline-block;
				width: 32px;
				height: 32px;
				background: url('../img/grey_close.svg') center no-repeat;
				background-size: contain;
			}

			.tmCopy a, .tmCopy button, .tmCopyLink button {
				display: inline-block;
				width: 24px;
				height: 24px;
				background: url('../img/copy_sign.svg') center no-repeat;
				background-size: contain;
			}

			.tmCopyLink button {
				width: 25px;
				height: 25px;
			}

			.tmCopyLink {
				margin-right: 16px;
				display: flex;
    			align-items: center;
			}			

	.tmspData {
		padding: 4px 0 0 24px;
	}

		.tmspdRow {
			padding: 16px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
			display: flex;
			justify-content: space-between;
		}

		.tmspdRow.noborder {
			border: none;
		}

		.tmspdRow.pt3 {
			padding-top: 3px;
		}

		.tmspdRow.flexStart {
			justify-content: flex-start;
		}

		.tmspdRow:first-child {
			border: none;
			padding-bottom: 8px;
		}

			.goalTitle {
				/*padding-right: 8px;*/
				width: 100%;
			}

				.goalTitle .spbBody textarea {
					/* height: 45px; */
					width: 100%;
					max-width: 437px;
					background: transparent;
					border: 1px solid transparent;
					border-radius: 4px;
					font-weight: 600;
					font-size: 24px;
					line-height: 29px;
					/* height: 0; */
					color: #141414;
					overflow: hidden;
					transition: border-color 0.3s ease-in-out;
					box-sizing: border-box;
					resize: none;
					font-family: 'Segoe UI', Arial, sans-serif;
					margin-left: -8px;
					height: auto;
				}

				.goalTitle textarea:hover, .goalTitle textarea:focus {
					border: 1px solid rgba(0, 0, 0, 0.15);	
					background: #fff;
				}

			.spBlock {				
				margin-right: 16px;
				width: 100px;
			}	

			.spBlock.w100percent {
				width: 100%;
			}
			
			.spBlock.w142 {
				width: 142px;
			}

			.spBlock.w120 {
				width: 120px;
			}

				.spbHeader {
					font-weight: 600;
					font-size: 10px;
					line-height: 12px;
					letter-spacing: 0.05em;
					text-transform: uppercase;
					color: #7a7a7a;
					margin-bottom: 8px;
				}

				.flexStart .spbBody {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
				}

				.flexStart .taskComments {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					flex-direction: column;
				}

				.oneComment {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					width: 100%;
					margin-bottom: 24px;
				}

				.flexStart .spbBody .currentUserComment, .flexStart .taskComments .currentUserComment {
					border-radius: 50%;
					width: 36px;
					height: 36px;				
					border: 2px solid #FFFFFF;
					margin-right: 12px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					flex-shrink: 0;
				}

				.flexStart .spbBody .currentUserComment, .flexStart .taskComments .currentUserComment {
					position: relative;
				}

				.flexStart .spbBody .currentUserComment.taskManagerSign:after,
				.flexStart .spbBody .currentUserComment.taskExecutorSign:after,
				.flexStart .taskComments .currentUserComment.taskManagerSign:after, 
				.flexStart .taskComments .currentUserComment.taskExecutorSign:after {
					content: '';
					position: absolute;
					right: -1px;
					bottom: -1px;
					width: 16px;
					height: 16px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					background: #FFFFFF;
					border: 1px solid rgba(0, 0, 0, 0.2);
					border-radius: 16px;					
				}

				.flexStart .spbBody .currentUserComment.taskManagerSign:after,
				.flexStart .taskComments .currentUserComment.taskManagerSign:after {
					background: url('../img/crown_gold.svg') center no-repeat;
				}

				.flexStart .spbBody .currentUserComment.taskExecutorSign:after, 
				.flexStart .taskComments .currentUserComment.taskExecutorSign:after {
					background: url('../img/star_green.svg') center no-repeat;
				}	

				.commentWrapper {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					
					margin-right: 16px;
					flex-direction: column;
					width: 100%;
				}

					.commentWrapperData {
						display: flex;
						width: 100%;
						margin-bottom: 8px;
						align-items: flex-end;
					}

					.commentWrapperData.flexColumn {
						flex-direction: column;
						align-items: flex-start;
						margin: 0;
					}

						.commentWrapperDataName {
							font-weight: 600;
							font-size: 12px;
							line-height: 14px;
							color: #141414;
							margin-right: 8px;
						}

						.commentWrapperDataName span {
							font-weight: 400;
							color: #7A7A7A;
						}

								.commentWrapperData.flexColumn .commentWrapperDataName {
									margin-bottom: 4px;
								}	

						.commentWrapperDataDate {
							font-size: 10px;
							line-height: 12px;
							color: #7A7A7A;
						}

						.commentWrapperText {
							font-size: 14px;
							line-height: 20px;
							color: #141414;
							padding: 6px 12px 6px 8px;
							background: #FFFFFF;
							border: 1px solid rgba(0, 0, 0, 0.15);
							border-radius: 4px;
							width: 100%;							
						}

						.commentShell {
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							align-items: flex-start;
							width: 100%;
						}

							.commentShell textarea {
								font-size: 14px;
								line-height: 20px;
								padding: 6px 12px 6px 8px;								
								background: #fff;								
								/*border: 1px solid rgba(0, 0, 0, 0.15);*/
								border-radius: 4px;
								color: #7A7A7A;								
								min-height: 32px !important;
							}	

							.commentSaveButton {
								margin-top: 7px;
								display: inline-block;
								background: #4BBF6B;
								border-radius: 24px;
								padding: 9px 12px;
								font-size: 12px;
								line-height: 14px;
								letter-spacing: 0.03em;
								text-transform: uppercase;
								color: #fff;
								display: none;
							}

							/*.commentShell textarea:focus + .commentSaveButton {*/
							.commentSaveButton.show {
								display: block;	
							}

				.flexStart .spbBody.vaTop {
					align-items: flex-start;
				}			

				/* .flexStart .spbBody textarea {
					flex-grow: 0;
					width: auto;
				} */

					.spbBody input[type=text] {
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						color: #141414;
						background: transparent;
						border: 1px solid transparent;
						padding: 0;
						width: 100%;
						transition: background-color, border-color 0.3s ease-in-out;
						border-radius: 4px;
						padding: 6px 8px;
						font-family: 'Segoe UI', Arial, sans-serif;
					}

					/* .shortInput .spbBody input[type=text] {

					} */

					.spbBody input[type=text]:hover, .spbBody textarea:hover {						
						background: rgba(0, 0, 0, 0.05);
					}

					.spbBody input[type=text]:disabled:hover {						
						background: transparent;
					}

					.spbBody input[type=text]:focus, .spbBody textarea:focus {
						background: #fff;
						border: 1px solid rgba(0, 0, 0, 0.15);	
					}

					.spbBody input[type=text]:focus-visible, .spbBody textarea:focus-visible {
						outline: none;	
					}

					.spbBody textarea {
						width: 100%;
						overflow: hidden;						
						/* min-height: 45px; */
						font-size: 14px;
						line-height: 20px;
						color: #141414;
						transition: background-color, border-color 0.3s ease-in-out;
						box-sizing: border-box;
						resize: none;
						font-family: 'Segoe UI', Arial, sans-serif;
					}

					.spbBody input[type=text].error, .spbBody textarea.error, .spbBody .cpfSelected.error, .userTaskBox.error  {
						outline: 1px solid #F94848;	
						color: #F94848;
					}

					.spbBody input[type=text].error::placeholder, .spbBody textarea.error::placeholder, .spbBody .cpfSelected.error .clientName {						
						color: #F94848;
					}					

					/* .spbBody textarea:hover {
						border: 1px solid rgba(0, 0, 0, 0.15);	
						background: #fff;
					} */

					.spbbClientData {
						display: flex;		
						justify-content: flex-start;
						align-items: center;
					}

						.spbbClientData .clientLogo {
							margin-right: 10px;
						}

						/* .spbbClientData .clientName {
							font-weight: 400;
						} */

					.spbBody .cpfSelected {
						color: #141414;
						background-color: transparent;
						padding: 0;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						min-height: 36px;
					}

					.spbBody .cpfSelected:after {
						display: none;
					  }
							
					.spbBody .cpfMenu ul li a {
						color: #141414;
					}

					.spbBody .cpfMenu ul li a.checkedClass {
						background: rgba(0, 0, 0, 0.03);
					}

					.spbBody .cpfSelected {
						padding: 4px 12px 4px 4px;
						display: inline-block;
						border-radius: 4px;						
						display: flex;
						width: 100%;
					}

					.spbBody .cpFilter:hover .cpfSelected {
						background: rgba(0, 0, 0, 0.05);
					}

					.spbbCheckBox {
						display: flex;
						align-items: center;
					}

						.checkboxBody {
							margin-left: 8px;	
						}

							.checkboxBodyWrapper {
								position: relative;	
								display: flex;
								align-items: center;
							}

								/*.checkboxBodyWrapper label {

								}*/
								
									.checkboxBodyWrapper label span {
										position: relative;
										display: block;
										width: 28px;
										height: 16px;
										cursor: pointer;
										background: url('../img/switcher_disabled.svg') center no-repeat;
										background-size: contain;
									}

									.checkboxBodyWrapper input[type=checkbox] {
										position: absolute;
										z-index: -1;
										opacity: 0;
										margin: 10px 0 0 20px;	
									}
									
									.checkboxBodyWrapper input[type=checkbox]:checked + span {
										background-image: url('../img/switcher_enabled.svg')
									}

		.tmspdFooter {
			margin-top: 24px;
			margin-bottom: 60px;
		}
		
			.tmspdFooter a, .tmspdFooter button {
				display: inline-block;
				background: #FFD600 url('../img/grey_plus.svg') 8px center no-repeat;
				background-size: 24px auto;
				border-radius: 24px;
				padding: 12px 12px 12px 36px;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.03em;
				text-transform: uppercase;
				color: #141414;
			}

.planCell {
	display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    height: 100%;
	width: 100%;
    z-index: 1;
	overflow-x: auto;
    flex-grow: 1;
	/* min-width: 1330px; */
}

.specReportButton {
	margin-top: 32px;
}

	.specReportButton button {
		display: inline-block;
		background: #FFD600;
		background-size: 24px auto;
		border-radius: 24px;
		padding: 12px 16px;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
		color: #141414;	
	}

.specReportLink {
	margin-top: 32px;
}	

	.specReportLinkHeader {
		margin-bottom: 4px;
		font-size: 16px;
   		line-height: 21px;
   		color: #7a7a7a;
	}

	.specReportLinkLink {
		
	}

		.specReportLinkLink a {
			font-size: 14px;
   			line-height: 19px;
   			color: #141414;
			text-decoration: underline;
		}

		.specReportLinkLink a:hover {
			text-decoration: none;
		}